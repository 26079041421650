body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-y: scroll;
}

body, button, .btn, input, .form-control {
  font-size: 0.75rem;
}

.form-check label {
  line-height: 1.38rem;
}

h2, h3, h4 {
  text-transform: uppercase;
  letter-spacing: .02em;
  margin-left: 0.25rem;

}

h2 {
  font-size: 150%;
}

h3 {
  font-size: 140%;
}

h4 {
  font-size: 120%;
}


code, .code {
  font-family: "Consolas";
}

label {
  margin-bottom: 0.2rem;
}

.log-table input, .log-table select {
  padding: 0 .15rem;
  width: 100%;
  height: 1.5rem;
}

.log-table-span {
  display: block;
}

.log-table thead th {
  vertical-align: initial;
  padding-bottom: 0.25em;
  background-color: #c4cacc;
}

.log-table thead th:first {
  background-color: red;
}

.log-table thead th label {
  width: 100%;
}

ul.pagination {
  float: right
}

.highlight-red {
  color: red;
  font-weight: 700;
}

.highlight-green {
  color: rgb(20, 154, 2);
  font-weight: 700;
}

#header {
  background: #c4cacc;
    position: sticky;
    padding-top: 1px;
    padding-bottom: 1px;
    top: 0px;
    z-index: 1;
    font-size: 0.8rem;
    border-bottom: #b3b8b9 solid 3px;
}

@media only screen and (max-width: 1390px) {
  .nav-text {
    display: none;
  }

  .nav-link {
    font-size: 1rem;
    padding-right: 0.2rem;
  }
}

.nav-text {
  padding-left: 0.2rem;
}

.nav-button:hover {
  transition: color 0.3s ease;
  color: #d7692f;
}


.panel {
  background-color: #ebeff0;
  padding: 8px;
  border: solid #fff 8px;
  min-height: 100px;
}

.panel:first-of-type {
border-left-width: 16px;
}

.panel:last-of-type {
  border-right-width: 16px;
}

.panel-gauge p {
  font-size: 100%;
  margin-bottom: 0.4rem;
}

.panel-gauge h2 {
  font-size: 200%
}

td.contrast {
  background-color: #e0e5e6;
}

td.numeric {
  font-family: 'Consolas';
}

td.numeric-contrast {
  background-color: #e0e5e6;
  font-family: 'Consolas';
}

.table, .table>:not(caption)>*>* {
  background-color: transparent;
}

.table td {
  border-top: 1px solid #d1d4d5;
}

.table th {
  border-top: none;
}

.log-table thead th {
  background: none;
  border-bottom: 2px solid #d1d4d5;
  border-top: none;
}

.react-bootstrap-table-page-btns-ul {
  margin-bottom: 0;
}

.panel-list .list-group-item{
  background-color: transparent;
  border-color: #d1d4d5;
  padding: 0.25rem 0.3rem;
}

table.searchPanel td {
  padding-top: 6px;
  padding-bottom: 6px;
  font-size: 110%;
}

table.searchPanel h4 {
  margin-bottom: 0.2rem;
}

table.searchPanel .indent {
  padding-left: 20px;
}